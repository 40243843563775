
import AbpBase from '@/lib/abpbase'
import { Component, Vue, Inject, Prop, Emit, Watch } from 'vue-property-decorator'
@Component({})
export default class FileAgent extends AbpBase {
  name: string = 'fileAgent'
  @Prop() label: string
  @Prop() disabled: boolean
  @Prop({ required: true, type: Array }) value: Array<any>
  fileRecords = []
  uploadHeaders = { 'X-Test-Header': 'vue-file-agent' }
  fileRecordsForUpload = [] // maintain an upload queue

  mounted() {}
  @Watch('value')
  watchfileRecords(newValue, oldValue) {
    this.fileRecords = newValue
  }

  filesSelected(fileRecordsNewlySelected) {
    var validFileRecords = fileRecordsNewlySelected.filter((fileRecord) => !fileRecord.error)
    this.fileRecordsForUpload = this.fileRecordsForUpload.concat(validFileRecords)
    this.$emit('input', this.fileRecords)
    this.$emit('modified', true)
  }
  onBeforeDelete(fileRecord) {
    var i = this.fileRecordsForUpload.find((x) => x.name() === fileRecord.name()) //.indexOf(fileRecord)
    if (i !== undefined) {
      // queued file, not yet uploaded. Just remove from the arrays
      this.fileRecordsForUpload.splice(i, 1)
      var k = this.fileRecords.indexOf(fileRecord)
      if (k !== -1) this.fileRecords.splice(k, 1)
    } else {
      this.$bvModal
        .msgBoxConfirm(this.L('¿Desea eliminar esta imagen?'), {
          size: 'sm',
          buttonSize: 'sm',
          okVariant: 'danger',
          okTitle: this.L('Sí'),
          cancelTitle: this.L('No'),
          footerClass: 'p-2',
          hideHeaderClose: false,
          centered: true
        })
        .then(async (value) => {
          if (value) {
            ;(this.$refs.vueFileAgent as any).deleteFileRecord(fileRecord) // will trigger 'delete' event
          }
        })
    }
  }
  fileDeleted(fileRecord) {
    var i = this.fileRecordsForUpload.indexOf(fileRecord)
    if (i !== -1) {
      this.fileRecordsForUpload.splice(i, 1)
    } else {
      this.deleteUploadedFile(fileRecord)
    }
  }
  deleteUploadedFile(fileRecord) {
    this.$emit('delete', fileRecord)
  }
}
