
import { Component, Vue, Inject, Prop, Watch } from "vue-property-decorator";
import AbpBase from "../../../lib/abpbase";
import Transferencia from "../../../store/entities/OZONE/transferencia";

@Component({})
export default class EditTransferencia extends AbpBase {
  @Prop({ type: Boolean, default: false }) value: boolean;
  @Prop({ type: String, default: "" }) title: string;
  @Prop({ type: String, default: "" }) action: string;
  transferencia: Transferencia = new Transferencia();
  disabled: boolean = false;
  urgent: boolean = false;
  form: any;

  beforeCreate() {
    this.form = this.$form.createForm(this, {
      name: "edit-transferencia-form",
    });
  }

  @Watch("value")
  syncTransferencia() {
    if (this.value) {
      this.transferencia = this.$store.state.transferencia.editTransferencia;
    } else {
      this.transferencia = null;
    }
  }

  async handleOk() {
    if (this.action === "redo") {
      await this.$store
        .dispatch({
          type: "transferencia/reenviar",
          data: { id: this.transferencia.id, iban: this.transferencia.iban },
        })
        .then(async (transferenciaID) => {
          if (this.urgent) {
            await this.$store
              .dispatch({
                type: "transferencia/urgent",
                data: { id: transferenciaID },
              })
              .then(async (data) => {
                this.$message.success("Transferencia emitida!");
              });
          }
        });
    } else if (this.action === "edit") {
      await this.$store
        .dispatch({
          type: "transferencia/editar",
          data: {
            id: this.transferencia.id,
            importe: this.transferencia.importe,
          },
        })
        .then(async () => {
          if (this.urgent) {
            await this.$store
              .dispatch({
                type: "transferencia/urgent",
                data: { id: this.transferencia.id },
              })
              .then(async (data) => {
                this.$message.success("Transferencia emitida!");
              });
          }
        });
    } else if (this.action === "force") {
      await this.$store.dispatch({
        type: "transferencia/forzar",
        data: {
          id: this.transferencia.id,
          importe: this.transferencia.importe,
        },
      });
    }

    this.$emit("save-success");
    this.$emit("input", false);
  }

  get confirmLoading() {
    return this.$store.state.transferencia.loading;
  }

  handleCancel() {
    this.$emit("input", false);
  }
}
