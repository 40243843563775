
import { Component, Vue, Inject, Prop, Watch } from "vue-property-decorator";
import AbpBase from "../../../lib/abpbase";
import Transferencia from "../../../store/entities/OZONE/transferencia";
import EditarTransferencia from "./edit-transferencia.vue";
import moment, { Moment } from "moment";

@Component({
    components: {
        EditarTransferencia
  },
})
export default class TransferenciaHistory extends AbpBase {
  name = "transferencia-history";
  @Prop(Object) value: Object | undefined;

  fechaDevolucion: Moment = null;
  editModalShowTitle: string = null;
  editModalShowAction: string = null;
  editModalShow: boolean = false;

  async rechazar(transfer) {
    if (this.fechaDevolucion) {
      await this.$store
        .dispatch({
          type: "transferencia/rechazar",
          data: {
            id: transfer.id,
            fechaDevolucion: this.fechaDevolucion.format("YYYY/MM/DD"),
          },
        })
        .then(async () => {
          this.$message.success("Transferencia marcada como rechazada");
          location.reload();
        });
    } else {
      this.$message.error("Debe seleccionar una fecha");
    }
  }


  reenviar(row) {
    this.$store.commit("transferencia/edit", row);
    this.editModalShow = true;
    this.editModalShowTitle = "Reenviar Transferencia";
    this.editModalShowAction = "redo";
  }

  forzar(row) {
    this.$store.commit("transferencia/edit", row);
    this.editModalShow = true;
    this.editModalShowTitle = "Forzar Transferencia";
    this.editModalShowAction = "force";
  }

  editar(row) {
    this.$store.commit("transferencia/edit", row);
    this.editModalShow = true;
    this.editModalShowTitle = "Editar Transferencia";
    this.editModalShowAction = "edit";
  }

  async park(row){
      await this.$store
        .dispatch({
          type: "transferencia/aparcar",
          data: { id: row.id },
        })
        .then(async (data) => {
          this.$message.success("Transferencia aparcada!");
          location.reload();
        });
  }

  async unpark(row){
      await this.$store
        .dispatch({
          type: "transferencia/reanudar",
          data: { id: row.id },
        })
        .then(async (data) => {
          this.$message.success("Transferencia reanudada!");
         location.reload();
        });
  }

  async emitirUrgente(row) {
    this.$message.loading("Generando fichero SEPA...");
    let transferenciaID = row.id;
    if (transferenciaID) {
      await this.$store
        .dispatch({
          type: "transferencia/urgent",
          data: { id: transferenciaID },
        })
        .then(async (data) => {
          this.$message.success("Transferencia emitida!");
          location.reload();
        });
    } else {
      this.$message.error("Error al generar SEPA", 5);
    }
  }


}
